import { API } from "../backend";
// import { APINOSA } from "../backend";

import axios from "axios";

//Info Calls
//................................................................
//create an info
console.log("API CALLS API is:", API);
export const createanInfo = (userId, token, info) => {
  return fetch(`${API}/info/create/${userId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      Authorization: `Bearer ${token}`,
    },
    body: info,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//get all infos
export const getInfos = () => {
  return fetch(`${API}/infos`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//delete info

export const deleteInfo = (infoId, userId, token) => {
  return fetch(`${API}/info/${infoId}/${userId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//get an info by id

export const getInfo = (infoId) => {
  return fetch(`${API}/info/${infoId}`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//update an info

export const updateInfo = (infoId, userId, token, info) => {
  return fetch(`${API}/info/${infoId}/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
    body: info,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//......................................................................
//Project calls

export const multipleFilesUpload = async (data, userId) => {
  try {
    await axios.post(`${API}/multipleFiles/create/${userId}`, data);
  } catch (error) {
    throw error;
  }
};

export const getallGalleryImgs = async () => {
  try {
    const { data } = await axios.get(`${API}/getallGalleryImgs`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMultipleFiles = async (projectTitle) => {
  try {
    const { data } = await axios.get(`${API}/getMultipleFiles/${projectTitle}`);
    return data;
  } catch (error) {
    throw error;
  }
};

//ImagesCollectionPostRequest

export const createImgs = (Img) => {
  return fetch(`${API}/upload-images`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      // Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
    body: Img,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//create a project
export const createaProject = (userId, token, project) => {
  return fetch(`${API}/project/create/${userId}`, {
    "Access-Control-Allow-Origin": "*",
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
    body: project,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//get all projects
export const getProjects = () => {
  return fetch(`${API}/projects`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//delete a project

export const deleteProject = (projectId, userId, token) => {
  return fetch(`${API}/project/${projectId}/${userId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//get a project by id

export const getProject = (projectId) => {
  return fetch(`${API}/project/${projectId}`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//update a project

export const updateProject = (projectId, userId, token, project) => {
  return fetch(`${API}/project/${projectId}/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
    body: project,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
