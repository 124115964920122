import React, { useState } from "react";
import { signin, authenticate, isAutheticated } from "../auth/auth.js";
import { Redirect } from "react-router-dom";
const SignIn = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
    loading: false,
    didRedirect: false,
  });

  const { email, password, error, loading, didRedirect } = values;
  const { user } = isAutheticated();

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, loading: true });
    signin({ email, password })
      .then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error, loading: false });
        } else {
          authenticate(data, () => {
            setValues({
              ...values,
              didRedirect: true,
            });
          });
        }
      })
      .catch(console.log("signin request failed"));
  };

  const performRedirect = () => {
    if (didRedirect) {
      if (user && user.role === 1) {
        return <Redirect to="/AdminDashboard" />;
      } else {
        return <Redirect to="/Dashboard" />;
      }
    }
    // if (isAutheticated()) {
    //   return <Redirect to="/AdminDashboard" />;
    // }
  };

  const loadingMessage = () => {
    return (
      loading && (
        <div className="col-md-6 offset-sm-3 text-left">
          <div className="alert alert-info text-center AlertStyle">
            <h5>...جاري تسجيل الدخول</h5>
          </div>
        </div>
      )
    );
  };

  const errorMessage = () => {
    return (
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-left">
          <div
            className="alert alert-danger AlertStyle text-center"
            style={{ display: error ? "" : "none" }}
          >
            {error}
          </div>
        </div>
      </div>
    );
  };

  const signinLayout = () => (
    <div className="row">
      <div className="col-md-6 offset-sm-3 text-right mb-4 mt-2">
        <div className=" text-center AddUserTextHeader ">تسجيل الدخول </div>{" "}
        <form>
          <div className="form-group">
            <label className="text-secondary AddUserLabel">
              البريد الالكتروني
            </label>
            <input
              className="form-control"
              type="email"
              onChange={handleChange("email")}
              value={email}
            />
          </div>

          <div className="form-group">
            <label className="text-secondary AddUserLabel">كلمة المرور</label>
            <input
              className="form-control"
              type="password"
              onChange={handleChange("password")}
              value={password}
            />
          </div>
          <button
            onClick={onSubmit}
            className="btn btn-success btn-block AddUserLabel"
          >
            {" "}
            تسجيل الدخول
          </button>
        </form>
      </div>

      <p className="text-white text-center">{JSON.stringify(values)}</p>
    </div>
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12  mb-4 mt-2">
          {errorMessage()}
          {loadingMessage()}
          {performRedirect()}
          {signinLayout()}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
