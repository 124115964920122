import React, { Component } from "react";
import "./App.css";
import Slider from "./component/Slider";
import Footer from "./component/Footer";
import Nav from "./component/Nav";
import { ConfigProvider } from "antd";
import Projects from "./component/Projects";
import Project from "./component/Project";
import Services from "./component/Services";
import Info from "./component/Info";
import WhoWeAre from "./component/WhoWeAre";
import Home from "./component/Home";
import ContactUs from "./component/ContactUs";
import CreateProject from "./Dashboard/CreateProject";
import CreateInfo from "./Dashboard/CreateInfo";
import SignIn from "./Dashboard/SignIn";
import Dashboard from "./Dashboard/Dashboard";
import ManageProjects from "./Dashboard/ManageProjects";
import ManageInfos from "./Dashboard/ManageInfos";

import UpdateProject from "./Dashboard/UpdateProject";
import UpdateInfo from "./Dashboard/UpdateInfo";

import AdminDashboard from "./Dashboard/AdminDashboard";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./auth/PrivateRoutes";
class App extends Component {
  render() {
    return (
      <ConfigProvider direction="rtl">
        <div>
          <Router>
            <Nav></Nav>
            <Slider></Slider>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/WhoWeAre">
                <WhoWeAre />
              </Route>
              <Route exact path="/Projects">
                <Projects />
              </Route>

              <Route exact path="/Services">
                <Services />
              </Route>
              <Route exact path="/ContactUs">
                <ContactUs />
              </Route>
              <Route exact path="/Info">
                <Info />
              </Route>

              <Route exact path="/SignIn">
                <SignIn />
              </Route>
            </Switch>
            <PrivateRoute
              path="/AdminDashboard"
              exact
              component={AdminDashboard}
            />
            <PrivateRoute path="/Dashboard" exact component={Dashboard} />
            <PrivateRoute
              path="/ManageProjects/CreateProject"
              exact
              component={CreateProject}
            />

            <PrivateRoute
              path="/ManageInfos/CreateInfo"
              exact
              component={CreateInfo}
            />

            <PrivateRoute
              path="/ManageProjects"
              exact
              component={ManageProjects}
            />

            <PrivateRoute path="/ManageInfos" exact component={ManageInfos} />

            <PrivateRoute
              path="/ManageProjects/UpdateProject/:projectId"
              exact
              component={UpdateProject}
            />

            <PrivateRoute
              path="/ManageInfos/UpdateInfo/:infoId"
              exact
              component={UpdateInfo}
            />

            <Route
              path="/Projects/Project/:projectId"
              exact
              component={Project}
            />
          </Router>

          <Footer></Footer>
        </div>
      </ConfigProvider>
    );
  }
}

export default App;
