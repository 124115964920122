import React from "react";
import { Image } from "antd";
import doc1 from "../img/docs/doc1.jpg";
import doc2 from "../img/docs/doc2.png";
import doc3 from "../img/docs/doc3.png";
import doc4 from "../img/docs/doc4.png";
import doc5 from "../img/docs/doc5.png";
import doc6 from "../img/docs/doc6.png";
import doc7 from "../img/docs/doc7.png";
import doc8 from "../img/docs/doc8.png";

import vision from "../img/vision.png";
import mission from "../img/mission.png";
import location from "../img/location.png";

const WhoWeAre = () => {
  return (
    <div className="container">
      <div className="WhoWeAreHeader">
        <h3 className="BriefHeader" style={{ color: "red" }}>
          {" "}
          من نحن
        </h3>
      </div>
      <div>
        <h4 className="WhoWeAreHeaders mb-4">
          {" "}
          أساسيات إدارة الانشاءات للمقاولات
        </h4>

        <p className="WhoWeAreText">
          لم تكن ريادة (مؤسسة اساسيات ادارة الانشائات للمقاولات) رغم حداثة عهدها
          لتخفى على أحد من عملائها فلقد دأبت منذ نشأتها على استقطاب الكوادر
          المحترفة وحشد العمالة المدربة وتطويرها وتثقيفها بمستلزمات السوق
          وتزويدها بأفضل المعدات والوسائل والأساليب التي تجعل من العمل خدمة
          هندسية راقية يتمتع بها عميلنا.
        </p>
      </div>
      <div>
        <h4 className="WhoWeAreHeaders"> رؤيتنا</h4>
        <div>
          <img className="vision" src={vision} width={200} alt="" />
        </div>

        <p className="WhoWeAreText">
          <strong style={{ color: "red" }}> مؤسستنا منذ نشأتها</strong>
          الى يومنا هذا تقدم استثناء فريد في كل ما قمنا به من اعمال في مجال
          (إدارة المقاولات والتنفيذ الهندسي) وسنستمر في مواكبة التطور التقني
          والعلمي في (الكم والكيف) محافظين على قدرتنا على الابداع والتفرد المطلق
          في أسواقنا المحلية والإقليمية.
        </p>
      </div>
      <div>
        <h4 className="WhoWeAreHeaders"> رسالتنا</h4>
        <div>
          <img className="mission" src={mission} width={200} alt="" />
        </div>
        <p className="WhoWeAreText">
          <strong style={{ color: "red" }}>
            لأن عميلنا هو القيمة الأكبر في حياة مؤسستنا،
          </strong>
          فإننا نسعى الى تحقيق كل ما يلبي احتياجاته من جودة عالية وخدمة مميزة
          وسعر منافس، وهذا ما يسمح لنا بالانتشار والتوسع وتحقيق المنفعة المشتركة
          في تقديم خدمات قيمة لمجتمعنا مستعين بطاقات الشباب السعودي الخلاق
          والمبدع للسير تحت مظلة رؤية حكومتنا الرشيدة 2030 التي تحقق الازدهار
          والرفعة للوطن حكومة وارضا وشعبا.{" "}
        </p>
      </div>

      <div>
        <h4 className="WhoWeAreHeaders "> أماكن انتشارنا</h4>
        <img className="mission" src={location} width={200} alt="" />
        <p className="WhoWeAreText">
          انطلاقا من جدة لمكة وثم جيزان ونحاول تعزيز تواجدنا في كافة ارجاء
          المملكة ليمتد تواجدنا عبر خصوصية مدنها الرائعة فبتنا
          <strong style={{ color: "red" }}>نغرس زهرة لنا في كل دوحة,</strong>
          فمن الخبر والدمام والقطيف الى الرياض والمدينة وينبع، واصلين جسورنا الى
          مدن الشمال سكاكا وعرعر وحفر الباطن.{" "}
        </p>
      </div>
      {/* Company Documents  */}
      <div className="container">
        <h4 className="WhoWeAreHeaders mb-4"> مستنداتنا</h4>

        <div className="row justify-content-center ">
          <Image.PreviewGroup>
            <div className="col-md-3 col-sm-12 d-flex justify-content-center mb-4">
              <Image
                className="WhoWeAreImage1"
                width={200}
                height={300}
                style={{
                  borderStyle: "inset ",
                  borderColor: "black",
                  borderWidth: "2.5px",
                }}
                src={doc1}
              />
            </div>
            <div className="col-md-3 col-sm-12 d-flex justify-content-center mb-4">
              <Image
                className="WhoWeAreImage1"
                width={200}
                height={300}
                style={{
                  borderStyle: "inset ",
                  borderColor: "black",
                  borderWidth: "2.5px",
                }}
                src={doc2}
              />
            </div>
            <div className="col-md-3 col-sm-12 d-flex justify-content-center mb-4">
              <Image
                className="WhoWeAreImage1"
                width={200}
                height={300}
                style={{
                  borderStyle: "inset ",
                  borderColor: "black",
                  borderWidth: "2.5px",
                }}
                src={doc3}
              />
            </div>
            <div className="col-md-3 col-sm-12 d-flex justify-content-center mb-4">
              <Image
                className="WhoWeAreImage1"
                width={200}
                height={300}
                style={{
                  borderStyle: "inset ",
                  borderColor: "black",
                  borderWidth: "2.5px",
                }}
                src={doc4}
              />
            </div>
            <div style={{ marginTop: "20px" }}></div>
            <div className="col-md-3 col-sm-12 d-flex justify-content-center mb-4">
              <Image
                className="WhoWeAreImage1"
                width={200}
                height={300}
                style={{
                  borderStyle: "inset ",
                  borderColor: "black",
                  borderWidth: "2.5px",
                }}
                src={doc5}
              />
            </div>{" "}
            <div className="col-md-3 col-sm-12 d-flex justify-content-center mb-4">
              <Image
                className="WhoWeAreImage1"
                width={200}
                height={300}
                style={{
                  borderStyle: "inset ",
                  borderColor: "black",
                  borderWidth: "2.5px",
                }}
                src={doc6}
              />
            </div>{" "}
            <div className="col-md-3 col-sm-12 d-flex justify-content-center mb-4">
              <Image
                className="WhoWeAreImage1"
                width={200}
                height={300}
                style={{
                  borderStyle: "inset ",
                  borderColor: "black",
                  borderWidth: "2.5px",
                }}
                src={doc7}
              />
            </div>{" "}
            <div className="col-md-3 col-sm-12 d-flex justify-content-center mb-4">
              <Image
                className="WhoWeAreImage1"
                width={200}
                height={300}
                style={{
                  borderStyle: "inset ",
                  borderColor: "black",
                  borderWidth: "2.5px",
                }}
                src={doc8}
              />
            </div>{" "}
          </Image.PreviewGroup>
        </div>
      </div>
      <br />
    </div>
  );
};
export default WhoWeAre;
