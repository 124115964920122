import { React, useState } from "react";
import { Row, Col, Typography, Layout, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { signout, isAutheticated } from "../auth/auth";
import { Link, withRouter } from "react-router-dom";
const { Header } = Layout;
const { Title } = Typography;

const Nav = () => {
  return (
    <Row justify="center">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Header className="header-fixed">
          <Row>
            <Col xl={6} lg={6} md={10} sm={15} xs={15}>
              <Title id="title-button" key="0">
                <Link
                  to="/"
                  className="HeaderTitleTextStyle"
                  style={{ fontSize: "16px", color: "white" }}
                >
                  أساسيات إدارة الانشاءات للمقاولات
                </Link>
                <Link to="/"></Link>
              </Title>
            </Col>
            {!isAutheticated() && (
              <Col xl={14} lg={14} md={10} sm={2} xs={2}>
                <Menu
                  theme="dark"
                  mode="horizontal"
                  // defaultSelectedKeys={[""]}
                  overflowedIndicator={<MenuOutlined />}
                >
                  <Menu.Item key="2" className="HeaderTextStyle">
                    مشاريعنا
                    <Link to="/Projects"></Link>
                  </Menu.Item>
                  <Menu.Item key="3" className="HeaderTextStyle">
                    {" "}
                    خدماتنا
                    <Link to="/Services"></Link>
                  </Menu.Item>
                  {/* <Menu.Item key="4" className="HeaderTextStyle">
                    معلومات فنية
                    <Link to="/Info"></Link>
                  </Menu.Item> */}
                  <Menu.Item key="5" className="HeaderTextStyle">
                    من نحن
                    <Link to="/WhoWeAre"></Link>
                  </Menu.Item>
                  <Menu.Item key="6" className="HeaderTextStyle">
                    اتصل بنا
                    <Link to="/ContactUs"></Link>
                  </Menu.Item>
                </Menu>
              </Col>
            )}

            {isAutheticated() && (
              <Col xl={14} lg={14} md={10} sm={2} xs={2}>
                <Menu
                  theme="dark"
                  mode="horizontal"
                  defaultSelectedKeys={[""]}
                  overflowedIndicator={<MenuOutlined />}
                >
                  <Menu.Item key="3" className="HeaderTextStyle">
                    مشاريع
                    <Link to="/ManageProjects"></Link>
                  </Menu.Item>
                  {/* <Menu.Item key="4" className="HeaderTextStyle">
                    معلومات فنية
                    <Link to="/ManageInfos"></Link>
                  </Menu.Item> */}

                  <Menu.Item
                    key="1"
                    className="HeaderTextStyle"
                    onClick={() => {
                      signout(() => console.log("signed"));
                    }}
                  >
                    تسجيل الخروج
                    <Link to="/"></Link>
                  </Menu.Item>
                </Menu>
              </Col>
            )}

            <Col xl={4} lg={4} md={4} sm={9} xs={9} justify="left">
              {" "}
              <div className="logo" />
            </Col>
          </Row>
        </Header>
      </Col>
    </Row>
  );
};

// export default Nav;
export default withRouter(Nav);
