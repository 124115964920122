import React, { useState, useEffect } from "react";
import { API } from "../backend";
import { Spin } from "antd";
import "antd/dist/antd.css";
import { Collapse } from "antd";
import { Image } from "antd";
import { getInfos } from "../Dashboard/apicalls";
import Pagination from "./Pagenation";
const { Panel } = Collapse;

const Info = () => {
  const [infos, setInfos] = useState([]);
  const [loading, setLoading] = useState([false]);

  const [CurrentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const preload = () => {
    getInfos().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setInfos(data);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    preload();
  }, []);

  const indexOfLastVideo = CurrentPage * postsPerPage;
  const indexOfFirstVideo = indexOfLastVideo - postsPerPage;
  const currentPosts = infos.slice(indexOfFirstVideo, indexOfLastVideo);

  if (loading)
    return (
      <div className="col-md-6 offset-sm-3 text-center mt-4">
        <Spin size="large" className="text-center" />
        <p className="text-center" style={{ color: "black", fontSize: "13px" }}>
          loading...
        </p>
      </div>
    );

  return (
    <div>
      {currentPosts.map((info, index) => {
        return (
          <Collapse bordered={true} defaultActiveKey={["0"]} className="mt-4">
            <Panel
              header={info.infoTitle}
              key="1"
              className="InfoHeaders"
              color="red"
            >
              <p className="InfoText">{info.infoDetails}</p>
              <Image
                src={`${API}/info/photo/${info._id}`}
                width={200}
                height={300}
                style={{
                  borderStyle: "inset ",
                  borderColor: "#CC8613",
                  borderWidth: "1.5px",
                }}
              />
            </Panel>
          </Collapse>
        );
      })}
      <div className=" col-md-12 justify-content-center d-flex mt-4">
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={infos.length}
          paginate={paginate}
        />
      </div>
    </div>
  );
};

export default Info;
