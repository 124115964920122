import React, { useState, useEffect } from "react";
import { isAutheticated } from "../auth/auth.js";
import { createanInfo } from "./apicalls";
import { Spin } from "antd";

const CreateInfo = () => {
  const { user, token } = isAutheticated();

  const [values, setValues] = useState({
    infoTitle: "",
    infoDetails: "",
    infoPhoto: "",
    loading: false,
    error: "",
    createdInfo: "",
    getaRedirect: false,
    formData: "",
  });

  const {
    infoTitle,
    infoDetails,
    loading,
    error,
    createdInfo,
    getaRedirect,
    formData,
  } = values;

  const preload = () => {
    setValues({ ...values, formData: new FormData() });
  };

  useEffect(() => {
    preload();
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true });
    createanInfo(user._id, token, formData).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          infoTitle: "",
          infoDetails: "",
          infoPhoto: "",
          loading: false,
          createdInfo: data.infoTitle,
        });
      }
    });
  };

  const handleChange = (name) => (event) => {
    console.log(`name is :${name}`);
    const value =
      name === "infoPhoto" ? event.target.files[0] : event.target.value;

    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };

  const successMessage = () => (
    <div
      className="alert alert-success text-center"
      style={{ display: createdInfo ? "" : "none" }}>
      <h4 className="AlertStyle">
        بنجاح <b>{createdInfo} </b>تمت إضافة{" "}
      </h4>
    </div>
  );

  const uploadingMessage = () => (
    <div
      className="alert alert-success text-center"
      style={{ display: loading ? "" : "none" }}>
      <h4 className="AlertStyle">.... جاري الرفع </h4>
    </div>
  );

  const errorMessage = () => {
    return (
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-left">
          <div
            className="alert alert-danger AlertStyle text-center"
            style={{ display: error ? "" : "none" }}>
            {error}
          </div>
        </div>
      </div>
    );
  };

  const createInfoForm = () => (
    <div>
      <div className=" text-center AddUserTextHeader mb-4">
        إضافة معلومة فنية{" "}
      </div>{" "}
      <form>
        <div className="form-group">
          <label className="btn btn-block btn-success">
            <input
              onChange={handleChange("infoPhoto")}
              type="file"
              name="infoPhoto"
              accept="image"
            />
          </label>
        </div>
        <div className="form-group">
          <label className="text-secondary AddUserLabel">عنوان المعلومة </label>
          <input
            onChange={handleChange("infoTitle")}
            name="infoTitle"
            className="form-control"
            value={infoTitle}
          />
        </div>
        <div className="form-group">
          <label className="text-secondary AddUserLabel">وصف للمعلومة </label>

          <textarea
            onChange={handleChange("infoDetails")}
            name="infoDetails"
            className="form-control"
            value={infoDetails}
          />
        </div>

        <button
          type="submit"
          onClick={onSubmit}
          className="btn btn-success btn-block AddUserLabel">
          إضافة المعلومة
        </button>
      </form>
    </div>
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-right mb-4 mt-2">
          {successMessage()}
          {errorMessage()}
          {uploadingMessage()}
          {createInfoForm()}
        </div>
      </div>
    </div>
  );
};

export default CreateInfo;
