import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import Pagination from "../component/Pagenation";

const OurProjects = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const preload = () => {
    const Projects = [
      {
        _id: "1",
        projectTitle: "فندق سهل جمال حي الصفا جدة ",
        projectDetails: " فندق سهل جمال حي الصفا جدة",
        localImages: [
          require("../projects/projectImage1.jpeg"),
          require("../projects/jamal2.jpeg"),
          require("../projects/jamal3.jpeg"),
        ],
      },
      {
        _id: "2",
        projectTitle: "Le shateau frontal فندق - أعمال تكييف شلر",
        projectDetails: "Le shateau frontal فندق - أعمال تكييف شلر",
        localImages: [
          require("../projects/projectImage2.jpeg"),
          require("../projects/Le shateau1.jpeg"),
          require("../projects/Le shateau2.jpeg"),
          require("../projects/Le shateau3.jpeg"),
          require("../projects/Le shateau4.jpeg"),
          require("../projects/Le shateau5.jpeg"),
          require("../projects/Le shateau6.jpeg"),
        ],
      },

      {
        _id: "3",
        projectTitle:
          "توريد وتركيب اعمال ضغط الهواء في مجمع الديني بلازا – جدة",
        projectDetails:
          "توريد وتركيب اعمال ضغط الهواء في مجمع الديني بلازا – جدة",
        localImages: [
          require("../projects/projectImage3.jpeg"),
          require("../projects/plaza2.jpg"),
          require("../projects/plaza3.jpg"),
        ],
      },

      {
        _id: "4",
        projectTitle:
          "تركيب اعمال الكلادنج للمواسير والدكت – محطة قطار السليمانية - جدة",
        projectDetails:
          "تركيب اعمال الكلادنج للمواسير والدكت – محطة قطار السليمانية - جدة",
        localImages: [
          require("../projects/projectImage4.jpeg"),
          require("../projects/sulimanya1.jpg"),
          require("../projects/sulimanya2.jpg"),
          require("../projects/sulimanya3.jpg"),
          require("../projects/sulimanya4.jpg"),
          require("../projects/sulimanya5.jpg"),
        ],
      },

      {
        _id: "5",
        projectTitle: "مشروع سكن عمال الشركة الصينية ومواقف الهيئة الملكية",
        projectDetails: " مشروع سكن عمال الشركة الصينية ومواقف الهيئة الملكية",
        localImages: [
          require("../projects/projectImage5.jpeg"),
          require("../projects/haya2.jpeg"),
          require("../projects/haya3.jpeg"),
          require("../projects/haya4.jpeg"),
          require("../projects/haya5.jpeg"),
          require("../projects/haya6.jpeg"),
          require("../projects/haya7.jpeg"),
          require("../projects/haya8.jpeg"),
          require("../projects/haya9.jpeg"),
        ],
      },

      {
        _id: "6",
        projectTitle: "جامعة العلوم والتكنولوجيا",
        projectDetails: " جامعة العلوم والتكنولوجيا",
        localImages: [
          require("../projects/projectImage6.jpeg"),
          require("../projects/UBT1.jpeg"),
          require("../projects/UBT2.jpeg"),
          require("../projects/UBT3.jpeg"),
          require("../projects/UBT4.jpeg"),
          require("../projects/UBT5.jpeg"),
          require("../projects/UBT6.jpeg"),
          require("../projects/UBT7.jpeg"),
          require("../projects/UBT8.jpeg"),
        ],
      },
      {
        _id: "7",
        projectTitle:
          "توريد وتركيب نظام التكييف المركزي – مستشفى صامطة العام - جيزان",
        projectDetails:
          "  توريد وتركيب نظام التكييف المركزي – مستشفى صامطة العام - جيزان ",
        localImages: [
          require("../projects/projectImage7.jpeg"),
          require("../projects/samta1.jpg"),
          require("../projects/samta2.jpg"),
          require("../projects/samta3.jpg"),
          require("../projects/samta4.jpg"),
        ],
      },
      {
        _id: "8",
        projectTitle: "مجموعة أعمال العثمان",
        projectDetails: "مجموعة أعمال العثمان",
        localImages: [
          require("../projects/projectImage8.jpeg"),
          require("../projects/oth1.jpeg"),
          require("../projects/oth2.jpeg"),
          require("../projects/oth3.jpeg"),
          require("../projects/oth4.jpeg"),
        ],
      },

      {
        _id: "9",
        projectTitle: "مختبرات البرج ",
        projectDetails: "مختبرات البرج ",
        localImages: [
          require("../projects/projectImage9.jpeg"),
          require("../projects/bg1.jpeg"),
          require("../projects/bg2.jpeg"),
          require("../projects/bg3.jpeg"),
          require("../projects/bg4.jpeg"),
          require("../projects/bg5.jpeg"),
          require("../projects/bg6.jpeg"),
          require("../projects/bg7.jpeg"),
          require("../projects/bg8.jpeg"),
          require("../projects/bg9.jpeg"),
        ],
      },

      {
        _id: "10",
        projectTitle:
          " تركيب اعمال الكلادنج للمواسير والدكت – محطة قطار السليمانية - جدة ",
        projectDetails:
          " تركيب اعمال الكلادنج للمواسير والدكت – محطة قطار السليمانية - جدة ",
        localImages: [
          require("../projects/projectImage10.jpeg"),
          require("../projects/trains1.jpg"),
          require("../projects/trains2.jpg"),
          require("../projects/trains3.jpg"),
          require("../projects/trains4.jpg"),
          require("../projects/trains5.jpg"),
        ],
      },
      {
        _id: "11",
        projectTitle: "فيلا الاندلس  ",
        projectDetails: "فيلا الاندلس ",
        localImages: [
          require("../projects/projectImage11.jpeg"),
          require("../projects/andalus1.jpeg"),
          require("../projects/andalus2.jpeg"),
          require("../projects/andalus3.jpeg"),
          require("../projects/andalus4.jpeg"),
          require("../projects/andalus5.jpeg"),
          require("../projects/andalus6.jpeg"),
          require("../projects/andalus7.jpeg"),
          require("../projects/andalus8.jpeg"),
          require("../projects/andalus9.jpeg"),
          require("../projects/andalus10.jpeg"),
          require("../projects/andalus11.jpeg"),
          require("../projects/andalus12.jpeg"),
        ],
      },
      {
        _id: "12",
        projectTitle: "مطاعم الشراع   ",
        projectDetails: "مطاعم الشراع - ابحر  ",
        localImages: [
          require("../projects/projectImage12.jpeg"),
          require("../projects/shera2.jpeg"),
          require("../projects/shera3.jpeg"),
          require("../projects/shera4.jpeg"),
          require("../projects/shera5.jpeg"),
          require("../projects/shera6.jpeg"),
        ],
      },

      {
        _id: "13",
        projectTitle: "   فندق ايلاف ",
        projectDetails: " فندق ايلاف جدة   ",
        localImages: [
          require("../projects/projectImage13.jpeg"),
          require("../projects/elaf1.jpeg"),
          require("../projects/elaf2.jpeg"),
          require("../projects/elaf4.jpeg"),
          require("../projects/elaf5.jpeg"),
          require("../projects/elaf6.jpeg"),
          require("../projects/elaf7.jpeg"),
          require("../projects/elaf8.jpeg"),
          require("../projects/elaf9.jpeg"),
          require("../projects/elaf10.jpeg"),
        ],
      },

      {
        _id: "14",
        projectTitle: "     مشروع الكيال جدة ",
        projectDetails: "   مشروع الكيال جدة   ",
        localImages: [
          require("../projects/projectImage14.jpeg"),
          require("../projects/kayal1.jpeg"),
          require("../projects/kayal2.jpeg"),
          require("../projects/kayal3.jpeg"),
          require("../projects/kayal4.jpeg"),
          require("../projects/kayal5.jpeg"),
        ],
      },
    ];

    setProjects(Projects);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    preload();
  }, []);

  if (loading)
    return (
      <div className="col-md-6 offset-sm-3 text-center mt-4">
        <Spin size="large" className="text-center" />
        <p className="text-center" style={{ color: "black", fontSize: "13px" }}>
          Loading...
        </p>
      </div>
    );

  return (
    <div className="container-fluid">
      <div className="row justify-content-center mb-4 mt-4 d-flex text-center">
        {projects.map((project, index) => (
          <div className="col-sm-12 col-md-6 col-lg-4 mb-4" key={project._id}>
            <img
              src={require(`../projects/projectImage${project._id}.jpeg`)}
              className="proImg"
              alt="Project image"
            ></img>
            <div className="text-center">
              <h4 className="ProjectTitle mt-2 text-center">
                {project.projectTitle}
              </h4>
              <div className="ProjectDesc mb-2 text-center">
                {project.projectDetails}
              </div>
            </div>
            <Link
              to={{
                pathname: `/Projects/Project/${project._id}`,
                state: {
                  localImages: project.localImages,
                  projectTitle: project.projectTitle,
                  projectDetails: project.projectDetails,
                },
              }}
            >
              <button className="btn btn-success btn-sm AddUserLabel mb-4">
                استعرض المزيد
              </button>
            </Link>
          </div>
        ))}
      </div>
      {/* <div className=" col-md-12 justify-content-center d-flex">
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={projects.length}
          paginate={paginate}
        />
      </div> */}
    </div>
  );
};

export default OurProjects;
