import React from "react";

export const Dashboard = () => {
  return (
    <div>
      <h2 className="text-center"> Here we go we have got User dashboard</h2>
    </div>
  );
};

export default Dashboard;
