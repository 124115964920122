import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import SwiperCore, { Autoplay } from "swiper";
import { getProject } from "../Dashboard/apicalls";
import { getMultipleFiles } from "../Dashboard/apicalls";
import { useMediaQuery } from "react-responsive";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";

const Project = ({ match }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 800px)" });
  SwiperCore.use([Autoplay]);

  const [slidesPerView] = useState(3);
  const [mobileslidesPerView] = useState(2);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const localImages = location.state.localImages || [];
  const projectTitle = location.state.projectTitle || "Project Title"; // Replace with your default title
  const projectDetails = location.state.projectDetails || "Project Details"; // Replace with your default details

  useEffect(() => {
    setLoading(true);
    // You can load additional project data here
    setLoading(false);
  }, []);

  return (
    <div className="col-md-12 mb-3 justify-content-center">
      <h4 className="ProjectTitle text-center mt-4"> {projectTitle} </h4>
      <div className="ProjectDesc mb-2">{projectDetails}</div>
      <h4 className="ProjectTitle text-center mb-4">صور من المشروع </h4>

      {isBigScreen && (
        <Swiper
          autoplay={{ delay: 3000 }}
          spaceBetween={20}
          slidesPerView={slidesPerView}
        >
          {localImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt="img" className="proImg" height="200" />
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      {isTabletOrMobile && (
        <Swiper
          autoplay={{ delay: 3000 }}
          spaceBetween={10}
          slidesPerView={mobileslidesPerView}
        >
          {localImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt="img" className="proImg" height="200" />
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <div className="swiper-pagination"></div>
    </div>
  );
};

export default Project;
