import service1 from "../img/services/1.png";
import service2 from "../img/services/2.png";
import service3 from "../img/services/3.png";
import service4 from "../img/services/4.png";
import service5 from "../img/services/5.png";
import service6 from "../img/services/6.png";
import service7 from "../img/services/7.png";
import service8 from "../img/services/8.png";

const OurServices = () => {
  return (
    <div className="container">
      <div className="mt-4 "></div>
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-md-12 text-center">
          <button
            onClick={() =>
              window.open(
                "https://drive.google.com/file/d/1bKMiMgqZcPz3PRhwlDB4Lmtl8wWHUvk_/view?usp=sharing",
                "_blank"
              )
            }
            className="btn btn-success btn-sm AddUserLabel mb-4"
          >
            {/* <img src={pdfIcon} alt="PDF Icon" /> */}
            <span>استعرض الملف التعريفي </span>
          </button>

          {/* <button className="btn btn-success btn-sm AddUserLabel mb-4">
                استعرض المزيد
              </button> */}
        </div>
      </div>

      <div className="row d-flex  mb-4">
        <div className="col-md-3 col-sm-12 order-md-1 d-flex justify-content-center mt-4 ">
          <img src={service1} height={60} alt="" />
        </div>

        <div className="col-md-3 col-sm-12 order-md-5   text-center justify-content-center mt-4  ">
          <tex className="ServiceText">خدمات إنشاء المباني </tex>
        </div>

        <div className="col-md-3 col-sm-12 order-md-2  d-flex justify-content-center mt-4 ">
          <img src={service2} height={60} alt="" />
        </div>

        <div className="col-md-3 col-sm-12 order-md-6   text-center justify-content-center mt-4 ">
          <tex className="ServiceText">خدمات تشطيب المباني </tex>
        </div>

        <div className="col-md-3 col-sm-12  order-md-3 d-flex justify-content-center mt-4 ">
          <img src={service3} height={60} alt="" />
        </div>
        <div className="col-md-3 col-sm-12 order-md-7   text-center justify-content-center mt-4 ">
          <tex className="ServiceText"> خدمة صيانة المباني </tex>
        </div>

        <div className="col-md-3 col-sm-12 order-md-4  d-flex justify-content-center mt-4 ">
          <img src={service4} height={60} alt="" />
        </div>
        <div className="col-md-3 col-sm-12 order-md-8   text-center justify-content-center mt-4 ">
          <tex className="ServiceText"> أعمال الكهرباء والميكانيك </tex>
        </div>
      </div>
      <div className="row d-flex mb-4 mt-4">
        <div className="col-md-3 col-sm-12 order-md-1  d-flex justify-content-center mt-4 ">
          <img src={service5} height={60} alt="" />
        </div>

        <div className="col-md-3 col-sm-12 order-md-5   text-center justify-content-center mt-4 ">
          <tex className="ServiceText"> أعمال التكييف </tex>
        </div>

        <div className="col-md-3 col-sm-12 order-md-2  d-flex justify-content-center mt-4 ">
          <img src={service6} height={60} alt="" />
        </div>
        <div className="col-md-3 col-sm-12 order-md-6   text-center justify-content-center mt-4 ">
          <tex className="ServiceText"> خدمات الصحية </tex>
        </div>

        <div className="col-md-3 col-sm-12  order-md-3 d-flex justify-content-center mt-4 ">
          <img src={service7} height={60} alt="" />
        </div>
        <div className="col-md-3 col-sm-12 order-md-7   text-center justify-content-center mt-4 ">
          <tex className="ServiceText"> أعمال التمديدات الكهربائية </tex>
        </div>

        <div className="col-md-3 col-sm-12  order-md-4 d-flex justify-content-center mt-4 ">
          <img src={service8} height={60} alt="" />
        </div>
        <div className="col-md-3 col-sm-12 order-md-8   text-center justify-content-center mt-4 ">
          <tex className="ServiceText"> أعمال التيار الخفيف </tex>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
