import React from "react";
import "antd/dist/antd.css";
import { Form, Input, Button, Card } from "antd";
function ContactFormAndMap() {
  return (
    <div className="MapIfram row justify-content-center">
      {/*

        <div className="col-md-6 col-sm-12 justify-content-center  ">

            <Form className=" justify-content-center ">
                <p className="ContactFormLabels"> الاسم :</p>

                <Form.Item>
                  <Input />
                </Form.Item>
                <p className="ContactFormLabels"> البريد الالكتروني :</p>

                <Form.Item>
                  <Input />
                </Form.Item>

            <div className=" justify-content-center">
              <Form.Item name={["user", "introduction"]}>
                <p className="ContactFormLabels"> الرسالة :</p>

                <Input.TextArea />
              </Form.Item>

              <Form.Item style={{ textAlign: "center" }}>
                <Button className="ContactFormLabels">ارسال</Button>
              </Form.Item>
            </div>
            </Form>
        </div>


 */}

      <iframe
        className="MapIfram mt-4"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d950645.7063079122!2d38.6506424560582!3d21.45046842621979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d01fb1137e59%3A0xe059579737b118db!2sJeddah%20Saudi%20Arabia!5e0!3m2!1sen!2str!4v1620133761406!5m2!1sen!2str"
        height="375px"
        width="375px"
        style={{ border: "1px solid gray" }}></iframe>
    </div>
  );
}

export default ContactFormAndMap;
