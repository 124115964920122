import React from 'react'

 const Brief = () => {
    return (
      <div className="container">
        <h3 className="BriefHeader mb-4"> أساسيات إدارة الانشاءات للمقاولات</h3>
        <p className="BriefText mb-2">
          يجتمع تحت سقف أساسيات ادارة الانشائات العديد من الأقسام والأنشطة التي
          تعنى في ((الاعمال الهندسية)) والتي أسست برعاية رئيس مجلس الإدارة على
          ارض المملكة العربية السعودية كمؤسسة وطنية خاصة وترفد كوادرها البشرية
          نخبة من الشباب السعودي المميز والمعطاء، مدعومين بخبرات اجنبية مميزة
          وتنفردأساسيات ادارة الانشاءات بتقديم كوادرمتخصصة في مجالات (المقاولات
          من اعمال بناء واعمال كهرباء وميكانيك) من خلال فرق عمل محترفة ومدربة
          تعنى بتنفيذ هذه الاعمال.
        </p>
      </div>
    );
}
export default Brief