import React from "react";
import "antd/dist/antd.css";
import { Layout } from "antd";

const Footer = Layout;

const Foo = () => {
  return (
    <Layout>
      <Footer
        style={{
          textAlign: "center",
          height: "calc(38vh - 55px)",
          backgroundColor: "#001529",
        }}
      >
        <p className="FooterTextArabic">أساسيات إدارة الانشاءات للمقاولات</p>
        <br />
        <div className="footerlogo"></div>

        <p className="text-center FooterText">
          Copyright &copy; {new Date().getFullYear()} Construction Management
          Fundamentals For Contacting
        </p>
      </Footer>
    </Layout>
  );
};
export default Foo;
