import React from 'react'
import Brief from "./Brief"
import Gallery from "./Gallery";

function Home() {
    return (
        <div>
            <Brief/>
            <Gallery/>
        </div>
    )
}

export default Home
