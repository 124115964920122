import React, { useState, useEffect } from "react";
import { getProjects, getProject, updateProject } from "./apicalls";
import { isAutheticated } from "../auth/auth.js";

const UpdateProject = ({ match }) => {
  const { user, token } = isAutheticated();

  const [values, setValues] = useState({
    projectTitle: "",
    projectBriefDescription: "",
    projectDetails: "",
    photo: "",
    loading: false,
    error: "",
    createdProject: "",
    getaRedirect: false,
    formData: "",
  });

  const {
    projectTitle,
    projectBriefDescription,
    projectDetails,
    loading,
    error,
    createdProject,
    getaRedirect,
    formData,
  } = values;

  const preload = (projectId) => {
    getProject(projectId).then((data) => {
      console.log(data);
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          projectTitle: data.projectTitle,
          projectBriefDescription: data.projectBriefDescription,
          projectDetails: data.projectDetails,
          formData: new FormData(),
        });
      }
    });
  };

  useEffect(() => {
    preload(match.params.projectId);
  }, []);

  //TODO: work on it
  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true });

    updateProject(match.params.projectId, user._id, token, formData).then(
      (data) => {
        if (data.error) {
          setValues({ ...values, error: data.error });
        } else {
          setValues({
            ...values,
            projectTitle: "",
            projectBriefDescription: "",
            projectDetails: "",
            photo: "",
            loading: false,
            createdProject: data.projectTitle,
          });
        }
      }
    );
  };

  const handleChange = (name) => (event) => {
    const value = name === "photo" ? event.target.files[0] : event.target.value;
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };

  const successMessage = () => (
    <div
      className="alert alert-success mt-3 text-center"
      style={{ display: createdProject ? "" : "none" }}>
      <h4 className="AlertStyle">
        <b>{createdProject} </b>تم تعديله بنجاح{" "}
      </h4>
    </div>
  );

  const createProjectForm = () => (
    <div>
      <div className=" text-center AddUserTextHeader mb-4">تعديل مشروع </div>{" "}
      <form>
        <div className="form-group">
          <label className="btn btn-block btn-success">
            <input
              onChange={handleChange("photo")}
              type="file"
              name="photo"
              accept="image"
              placeholder=" choose a file "
              multiple
            />
          </label>
        </div>
        <div className="form-group">
          <label className="text-secondary AddUserLabel">عنوان المشروع </label>
          <input
            onChange={handleChange("projectTitle")}
            name="photo"
            className="form-control"
            value={projectTitle}
          />
        </div>
        <div className="form-group">
          <label className="text-secondary AddUserLabel">
            وصف مختصر للمشروع{" "}
          </label>

          <textarea
            onChange={handleChange("projectBriefDescription")}
            name="photo"
            className="form-control"
            value={projectBriefDescription}
          />
        </div>

        <div className="form-group">
          <label className="text-secondary AddUserLabel">تفاصيل المشروع </label>

          <textarea
            onChange={handleChange("projectDetails")}
            name="photo"
            className="form-control"
            value={projectDetails}
          />
        </div>

        <button
          type="submit"
          onClick={onSubmit}
          className="btn btn-success btn-block AddUserLabel">
          تعديل المشروع
        </button>
      </form>
    </div>
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-right mb-4 mt-2">
          {successMessage()}
          {createProjectForm()}
        </div>
      </div>
    </div>
  );
};

export default UpdateProject;
