import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isAutheticated } from "../auth/auth.js";
import { getProjects, deleteProject } from "./apicalls";
import Pagination from "../component/Pagenation";
import { Spin } from "antd";

const ManageProjects = () => {
  const [projects, setProjects] = useState([]);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState([false]);
  const [deleting, setDeleting] = useState(false);
  const [deletemsg, setdeletemsg] = useState("");
  const [deleted, setDeleted] = useState(false);

  const [postsPerPage] = useState(4);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const { user, token } = isAutheticated();

  const preload = () => {
    getProjects().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setProjects(data);
      }
      setLoading(false);
      setDeleting(false);
    });
  };

  useEffect(() => {
    setLoading(true);

    preload();
  }, []);

  const indexOfLastVideo = CurrentPage * postsPerPage;
  const indexOfFirstVideo = indexOfLastVideo - postsPerPage;
  const currentPosts = projects.slice(indexOfFirstVideo, indexOfLastVideo);

  const deleteThisProject = (projectId) => {
    setDeleting(true);

    deleteProject(projectId, user._id, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        preload();
        setdeletemsg(data.message);
        setDeleted(true);
      }
    });
  };

  if (loading)
    return (
      <div className="col-md-6 offset-sm-3 text-center mt-4">
        <Spin size="large" className="text-center" />
        <p className="text-center" style={{ color: "black", fontSize: "13px" }}>
          loading...
        </p>
      </div>
    );

  const deletionMessage = () => (
    <div
      className="alert alert-success text-center"
      style={{ display: deleting ? "" : "none" }}>
      <h4 className="AlertStyle">...جاري الازالة </h4>
    </div>
  );

  const deletedMessage = () => (
    <div
      className="alert alert-success text-center"
      style={{ display: deleted ? "" : "none" }}>
      <h4 className="AlertStyle">{deletemsg}</h4>
    </div>
  );

  const manageProjectsLayout = () => (
    <div>
      <div className=" row text-right mb-4 mt-4">
        <h5 className="col-md-6  offset-sm-3   text-center AddUserTextHeader">
          جميع المشاريع
        </h5>

        <Link
          to="/ManageProjects/CreateProject"
          className="DashboardLinks col-md-3">
          إضافة مشروع
        </Link>
      </div>{" "}
      <div className="row mb-4 ">
        <div className="col-12 mt-4">
          {currentPosts.map((project, index) => {
            return (
              <div key={index} className="row text-center mb-2 ">
                <div className="col-4">
                  <Link
                    className="btn btn-success btn-sm"
                    to={`/ManageProjects/updateProject/${project._id}`}>
                    <span className="ManagedashboardButtons">تعديل</span>
                  </Link>
                </div>
                <div className="col-4">
                  <button
                    onClick={() => {
                      deleteThisProject(project._id);
                    }}
                    className="btn btn-danger btn-sm ManagedashboardButtons">
                    إزالة
                  </button>
                </div>

                <div className="col-4">
                  <h4 className="text-right ManagedashboardText">
                    {project.projectTitle}
                  </h4>
                </div>

                <hr style={{ width: "100%" }}></hr>
              </div>
            );
          })}
        </div>
        <div className=" col-md-12 justify-content-center d-flex">
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={projects.length}
            paginate={paginate}
          />
        </div>
      </div>
    </div>
  );
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12  mb-4 mt-2">
          {deletionMessage()}
          {deletedMessage()}
          {manageProjectsLayout()}
        </div>
      </div>
    </div>
  );
};

export default ManageProjects;
