import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import SwiperCore, { Autoplay } from "swiper";
import { useMediaQuery } from "react-responsive";
import { Spin } from "antd";

import borg from "../gallery/borg.jpeg";
import borg2 from "../gallery/borg2.jpeg";
import borg3 from "../gallery/borg3.jpeg";
import borg4 from "../gallery/borg4.jpeg";
import borg5 from "../gallery/borg5.jpeg";
import dewan1 from "../gallery/dewan1.jpeg";
import dewan2 from "../gallery/dewan2.jpeg";
import Elaf1 from "../gallery/Elaf1.jpeg";
import Elaf2 from "../gallery/Elaf2.jpeg";
import Elaf3 from "../gallery/Elaf3.jpeg";
import haya1 from "../gallery/haya1.jpeg";
import haya2 from "../gallery/haya2.jpeg";
import Jamal1 from "../gallery/Jamal1.jpeg";
import Jamal2 from "../gallery/Jamal2.jpeg";
import leshatea from "../gallery/leshatea.jpeg";
import Mdrd1 from "../gallery/Mdrd1.jpeg";
import Mdrd2 from "../gallery/Mdrd2.jpeg";
import Othman1 from "../gallery/Othman1.jpeg";
import Othman2 from "../gallery/Othman2.jpeg";
import Shera1 from "../gallery/Shera1.jpeg";
import smok from "../gallery/smok.jpg";
import UBT1 from "../gallery/UBT1.jpeg";
import UBT2 from "../gallery/UBT2.jpeg";
import UBT3 from "../gallery/UBT3.jpeg";
import vil1 from "../gallery/vil1.jpeg";
import vil2 from "../gallery/vil2.jpeg";
import Villa1 from "../gallery/Villa1.jpeg";
import Villa2 from "../gallery/Villa2.jpeg";

const Gallery = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 800px)" });

  SwiperCore.use([Autoplay]);
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [slidesPerView, SetslidesPerView] = useState(4);
  const [mobileslidesPerView, SetmobileslidesPerView] = useState(2);

  useEffect(() => {
    setLoading(true);
    getMultipleFilesList();
  }, []);

  const getMultipleFilesList = async () => {
    // Simulate loading multipleFiles
    // Replace this with your logic if needed
    try {
      // Simulate loading multipleFiles
      const fileslist = [
        borg,
        borg2,
        borg3,
        borg4,
        borg5,
        dewan1,
        dewan2,
        Elaf1,
        Elaf2,
        Elaf3,
        haya1,
        haya2,
        Jamal1,
        Jamal2,
        leshatea,
        Mdrd1,
        Mdrd2,
        Othman1,
        Othman2,
        Shera1,
        smok,
        UBT1,
        UBT2,
        UBT3,
        vil1,
        vil2,
        Villa1,
        Villa2,
      ]; // Use your imported images
      setMultipleFiles(fileslist);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  if (loading)
    return (
      <div className="col-md-6 offset-sm-3 text-center">
        <Spin size="large" className="text-center" />
        <p className="text-center" style={{ color: "black", fontSize: "13px" }}>
          Loading...
        </p>
      </div>
    );

  return (
    <div className="mt-4">
      <h3 className="GalleryText mb-4">معرض الصور</h3>
      {isBigScreen && (
        <Swiper
          className="mb-4 justify-content-center"
          autoplay={{ delay: 1000 }}
          spaceBetween={20}
          slidesPerView={slidesPerView}
        >
          {multipleFiles.map((element, index) => (
            <div key={index}>
              <div className="row">
                <SwiperSlide>
                  <div className="justify-content-center">
                    <img
                      src={element}
                      height="200"
                      className="ImgGallery1"
                      alt="img"
                    />
                  </div>
                </SwiperSlide>
              </div>
            </div>
          ))}
        </Swiper>
      )}

      {isTabletOrMobile && (
        <Swiper
          className="mb-4 justify-content-center"
          autoplay={{ delay: 1000 }}
          spaceBetween={10}
          slidesPerView={mobileslidesPerView}
        >
          {multipleFiles.map((element, index) => (
            <div key={index}>
              <div className="row">
                <SwiperSlide>
                  <div className="justify-content-center">
                    <img
                      src={element}
                      height="200"
                      className="ImgGallery1"
                      alt="img"
                    />
                  </div>
                </SwiperSlide>
              </div>
            </div>
          ))}
        </Swiper>
      )}
      <div className="swiper-pagination"></div>
    </div>
  );
};

export default Gallery;
