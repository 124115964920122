import React, { useState, useEffect } from "react";
import { getInfos, getInfo, updateInfo } from "./apicalls";
import { isAutheticated } from "../auth/auth.js";

const UpdateInfo = ({ match }) => {
  const { user, token } = isAutheticated();

  const [values, setValues] = useState({
    infoTitle: "",
    infoDetails: "",
    infoPhoto: "",
    loading: false,
    error: "",
    createdInfo: "",
    getaRedirect: false,
    formData: "",
  });

  const {
    infoTitle,
    infoDetails,
    infoPhoto,
    loading,
    error,
    createdInfo,
    getaRedirect,
    formData,
  } = values;

  const preload = (infoId) => {
    getInfo(infoId).then((data) => {
      console.log(data);
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          infoTitle: data.infoTitle,
          infoDetails: data.infoDetails,
          formData: new FormData(),
        });
      }
    });
  };

  useEffect(() => {
    preload(match.params.infoId);
  }, []);

  //TODO: work on it
  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true });

    updateInfo(match.params.infoId, user._id, token, formData).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          infoTitle: "",
          infoDetails: "",
          infoPhoto: "",
          loading: false,
          createdInfo: data.infoTitle,
        });
      }
    });
  };

  const handleChange = (name) => (event) => {
    const value = name === "photo" ? event.target.files[0] : event.target.value;
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };

  const successMessage = () => (
    <div
      className="alert alert-success mt-3 text-center"
      style={{ display: createdInfo ? "" : "none" }}>
      <h4 className="AlertStyle">
        <b>{createdInfo} </b>تم تعديله بنجاح{" "}
      </h4>
    </div>
  );

  const createInfoForm = () => (
    <div>
      <div className=" text-center AddUserTextHeader mb-4">
        تعديل معلومة فنية{" "}
      </div>{" "}
      <form>
        <div className="form-group">
          <label className="btn btn-block btn-success">
            <input
              onChange={handleChange("photo")}
              type="file"
              name="photo"
              accept="image"
              placeholder=" choose a file "
              multiple
            />
          </label>
        </div>
        <div className="form-group">
          <label className="text-secondary AddUserLabel">عنوان المعلومة </label>
          <input
            onChange={handleChange("infoTitle")}
            name="photo"
            className="form-control"
            value={infoTitle}
          />
        </div>
        <div className="form-group">
          <label className="text-secondary AddUserLabel">وصف المعلومة </label>

          <textarea
            onChange={handleChange("infoDetails")}
            name="photo"
            className="form-control"
            value={infoDetails}
          />
        </div>

        <button
          type="submit"
          onClick={onSubmit}
          className="btn btn-success btn-block AddUserLabel">
          تعديل المعلومة
        </button>
      </form>
    </div>
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-right mb-4 mt-2">
          {successMessage()}
          {createInfoForm()}
        </div>
      </div>
    </div>
  );
};

export default UpdateInfo;
