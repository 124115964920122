import React from "react";

export const AdminDashboard = () => {
  return (
    <div>
      <h2 className="text-center mb-4 mt-4 ControlPanel"> لوحة التحكم</h2>

      <h4 className="text-center mb-4 mt-4 ControlPanel2">
        {" "}
        تم تسجيل الدخول بنجاح ....أهلا بك
      </h4>
    </div>
  );
};

export default AdminDashboard;
