import React, { useState, useEffect } from "react";
import { isAutheticated } from "../auth/auth.js";
import { createaProject } from "./apicalls";
import { multipleFilesUpload } from "./apicalls";

const CreateProject = () => {
  const { user, token } = isAutheticated();
  const [uploadingimages, setuploadingimages] = useState(false);
  const [imgCollection, setImgCollection] = useState();

  const [imagesSuccess, setimagesSuccess] = useState("");
  const [imagesfail, setimagesfail] = useState("");

  const [values, setValues] = useState({
    projectTitle: "",
    projectBriefDescription: "",
    projectDetails: "",
    photo: "",
    loading: false,
    error: "",
    createdProject: "",
    getaRedirect: false,
    formData: "",
  });

  const {
    projectTitle,
    projectBriefDescription,
    projectDetails,
    loading,
    error,
    createdProject,
    getaRedirect,
    formData,
  } = values;

  const { resp, Imageserror } = values;

  const preload = () => {
    setValues({ ...values, formData: new FormData() });
  };

  useEffect(() => {
    console.log(`imageCollection is : ${[imgCollection]}`);

    preload();
  }, []);

  const onChangeFile = (e) => {
    setImgCollection(e.target.files);
  };

  const onSubmittingPhotos = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("title", values.projectTitle);
    try {
      for (let i = 0; i < imgCollection.length; i++) {
        formData.append("files", imgCollection[i]);
      }
    } catch (error) {
      setimagesfail("لم يتم اختيار اي صورة");
    }

    setuploadingimages(true);
    await multipleFilesUpload(formData, user._id);
    setimagesSuccess("تم رفع الصور ");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true });
    createaProject(user._id, token, formData).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          projectTitle: "",
          projectBriefDescription: "",
          projectDetails: "",
          photo: "",
          loading: false,
          createdProject: data.projectTitle,
        });
      }
    });
  };

  const handleChange = (name) => (event) => {
    const value = name === "photo" ? event.target.files[0] : event.target.value;

    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };

  const successMessage = () => (
    <div
      className="alert alert-success text-center"
      style={{ display: createdProject ? "" : "none" }}>
      <h4 className="AlertStyle">
        بنجاح <b>{createdProject} </b>تمت إضافة{" "}
      </h4>
    </div>
  );

  const errorMessage = () => {
    return (
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-left">
          <div
            className="alert alert-danger AlertStyle text-center"
            style={{ display: error ? "" : "none" }}>
            {error}
          </div>
        </div>
      </div>
    );
  };

  const ImageserrorMessage = () => {
    return (
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-left">
          <div
            className="alert alert-danger AlertStyle text-center"
            style={{ display: imagesfail ? "" : "none" }}>
            {imagesfail}
          </div>
        </div>
      </div>
    );
  };

  // const ImageUploadingMessage = () => (
  //   <div
  //     className="alert alert-success text-center"
  //     style={{ display: uploadingimages ? "" : "none" }}>
  //     <h4 className="AlertStyle">
  //       <b>{} </b>جار رفع الصور{" "}
  //     </h4>
  //   </div>
  // );

  const ImageUploadingSuccess = () => (
    <div
      className="alert alert-success text-center"
      style={{ display: imagesSuccess ? "" : "none" }}>
      <h4 className="AlertStyle">
        <b>{} </b>تم رفع الصور{" "}
      </h4>
    </div>
  );

  // const ImagessuccessMessage = () => (
  //   <div
  //     className="alert alert-success text-center"
  //     style={{ display: imageUploadingRespone ? "" : "none" }}>
  //     <h4 className="AlertStyle">
  //       <b>{imageUploadingRespone} </b>{" "}
  //     </h4>
  //   </div>
  // );

  const createProjectForm = () => (
    <div>
      <div className=" text-center AddUserTextHeader mb-4">إضافة مشروع </div>{" "}
      <form>
        <div className="form-group">
          <label className="btn btn-block btn-success">
            <input
              onChange={handleChange("photo")}
              type="file"
              name="photo"
              accept="image"
            />
          </label>
        </div>
        <div className="form-group">
          <label className="text-secondary AddUserLabel">عنوان المشروع </label>
          <input
            onChange={handleChange("projectTitle")}
            name="photo"
            className="form-control"
            value={projectTitle}
          />
        </div>
        <div className="form-group">
          <label className="text-secondary AddUserLabel">
            وصف مختصر للمشروع{" "}
          </label>

          <textarea
            onChange={handleChange("projectBriefDescription")}
            name="photo"
            className="form-control"
            value={projectBriefDescription}
          />
        </div>
        <div className="form-group">
          <label className="text-secondary AddUserLabel">تفاصيل المشروع </label>

          <textarea
            onChange={handleChange("projectDetails")}
            name="photo"
            className="form-control"
            value={projectDetails}
          />
        </div>
        <div className="form-group">
          <label className="btn btn-block btn-success">
            <input
              onChange={(e) => onChangeFile(e)}
              type="file"
              name="photo"
              accept="image"
              multiple
            />
          </label>
        </div>

        <button
          type="submit"
          onClick={onSubmittingPhotos}
          className="btn btn-success btn-block AddUserLabel mb-4">
          رفع صور المشروع
        </button>
        <i className="Note1 ">
          يرجى أولا رفع صور المشروع والانتظار حتى الاتنتهاء ثم إضافة المشروع
        </i>

        <button
          type="submit"
          onClick={onSubmit}
          className="btn btn-success btn-block AddUserLabel mt-2">
          إضافة المشروع
        </button>
      </form>
    </div>
  );
  return (
    <div>
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-right mb-4 mt-2">
          {successMessage()}
          {errorMessage()}
          {ImageserrorMessage()}
          {ImageUploadingSuccess()}
          {createProjectForm()}
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
