import React from "react";
import "antd/dist/antd.css";
import { Carousel } from "antd";

const Slider = () => {
  return (
    <Carousel autoplay effect="fade">
      <div className="Carousel2"></div>
      <div className="Carousel3"> </div>
      <div className="Carousel4"> </div>
      <div className="Carousel5"> </div>
      <div className="Carousel6"> </div>
      <div className="Carousel7"> </div>
      <div className="Carousel8"> </div>
      <div className="Carousel9"> </div>
      <div className="Carousel10"> </div>
    </Carousel>
  );
};

export default Slider;
