import React from "react";
import ContactFormAndMap from "./ContactFormAndMap";
import telephone from "../img/contact/telephone.png";
import whatsapp from "../img/contact/whatsapp.png";
import telegram from "../img/contact/telegram.png";
import gmail from "../img/contact/gmail.png";

const ContactUs = () => {
  return (
    <div style={{ marginBottom: "100px" }} className="container-fluid ">
      <h3 className="BriefHeader mt-4" style={{ color: "red" }}>
        اتصل بنا
      </h3>
      <div className="mt-4">
        <div
          className="row"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
          }}>
          {/* phone */}
          <div className="col-md-3 col-sm-12 order-md-1 d-flex justify-content-center ">
            {/* <i className="fa fa-phone fa-2x" style={{ color: "#444444" }}></i> */}
            <img src={telephone} height={50} alt="" />
          </div>
          <div className="col-md-3  col-sm-12 order-md-5  text-center justify-content-center   mb-4">
            <a href="tel:+966555042984" className="ContactText">
              اتصل بنا
            </a>{" "}
            <h6>+966555042984</h6>
          </div>
          <br /> <br />
          <br />
          {/* Whataspp */}
          <div className="col-md-3 col-sm-12 order-md-2  d-flex justify-content-center  ">
            <img src={whatsapp} height={50} alt="" />
          </div>
          <div className="col-md-3  col-sm-12  order-md-6 justify-content-center  text-center mb-4 ">
            <div>
              <a
                href="https://api.whatsapp.com/send?phone=+966555042984"
                target="_blank"
                rel="noopener noreferrer">
                <text className="ContactText">أرسل رسالة</text>
              </a>{" "}
              <div></div> <h6>+966555042984</h6>
            </div>
          </div>
          <br /> <br />
          {/* Email */}
          <div className="col-md-3 col-sm-12 order-md-3 d-flex justify-content-center ">
            <img src={gmail} height={50} alt="" />
          </div>
          <div className="col-md-3  col-sm-12 order-md-7   justify-content-center text-center mb-4">
            <div>
              <a
                className="ContactText  break-text"
                href="mailto:info@cmf-time.com"
                target="_blank"
                rel="noopener noreferrer">
                أرسل بريد الكتروني
              </a>{" "}
            </div>
            <div>
              {" "}
              <h6>info@cmf-time.com</h6>
            </div>
          </div>
          {/* Telegram */}
          <div className="col-md-3  col-sm-12 order-md-4  d-flex justify-content-center ">
            <img src={telegram} height={50} alt="" />
          </div>
          <div className="col-md-3  col-sm-12 order-md-8  text-center justify-content-center">
            <a
              href="https://t.me/cemmas"
              target="_blank"
              rel="noopener noreferrer">
              <tex className="ContactText">أرسل رسالة</tex>
            </a>{" "}
            <h6>cmf-time</h6>
          </div>
        </div>
      </div>
      <div className="justify-content-center">
        <ContactFormAndMap></ContactFormAndMap>
      </div>
    </div>
  );
};

export default ContactUs;
